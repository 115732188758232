import React from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, NavLink } from "react-router-dom";
import Footer from "../components/Footer";

// import Table from 'react-bootstrap/Table';
const tableStyle = {
  border: '1px solid black',
  borderCollapse: 'collapse',
  width: '100%',
};
const thStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
};

const tdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
};
function JobListings() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <header className="w-full flex items-center bg-white top-0 fixed justify-between px-4 md:px-10 shadow-lg py-3">
        <div className="flex items-center gap-4">
          <Link to="/">
            <img
              className="h-16 md:h-20"
              src="assets/logo.PNG"
              alt="Yangkhor logo"
            />
          </Link>
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-blue-300 text-lg md:text-2xl">
          Recruitment System and Vacancy Management
          </span>
        </div>

        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon
              className="h-6 w-6 hover:text-blue-500"
              aria-hidden="true"
            />
          </button>
        </div>

        <div className="hidden lg:flex lg:gap-10">
          {/* <Link className="text-gray-900 text-sm leading-6 font-semibold hover:text-blue-500 hover:uppercase">
            How to apply?
          </Link>
          <Link className="text-gray-900 text-sm leading-6 font-semibold hover:text-blue-500 hover:uppercase">
            Track Your application
          </Link> */}
          {/* <NavLink
            to=""
            className={(navData) =>
              navData.isActive
                ? "text-blue-500 text-sm font-semibold leading-6 uppercase"
                : "text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500 hover:uppercase"
            }
          >
            Log in <span aria-hidden="true">&rarr;</span>
          </NavLink> */}
        </div>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Yang Khor Private Ltd</span>
                <img
                  className="h-16 w-auto"
                  src="assets/logo.PNG"
                  alt="company logo"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon
                  className="h-6 w-6 hover:text-red-500"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="flex flex-col py-6">
                  <NavLink
                    to=""
                    className={(navData) =>
                      navData.isActive
                        ? "-mx-3 block rounded-lg px-3 py-2 text-sm font-semibold leading-7 text-blue-500 bg-gray-50"
                        : "-mx-3 block rounded-lg px-3 py-2 text-sm font-semibold leading-7 text-gray-900 hover:text-blue-500 hover:bg-gray-50"
                    }
                  >
                    How to Apply?
                  </NavLink>
                  <NavLink
                    to=""
                    className={(navData) =>
                      navData.isActive
                        ? "-mx-3 block rounded-lg px-3 py-2 text-sm font-semibold leading-7 text-blue-500 bg-gray-50"
                        : "-mx-3 block rounded-lg px-3 py-2 text-sm font-semibold leading-7 text-gray-900 hover:text-blue-500 hover:bg-gray-50"
                    }
                  >
                    Track Your application
                  </NavLink>
                </div>
                <div className="py-6">
                  <NavLink
                    to=""
                    className={(navData) =>
                      navData.isActive
                        ? "-mx-3 block rounded-lg px-3 py-2 text-sm font-semibold leading-7 text-blue-500 bg-gray-50"
                        : "-mx-3 block rounded-lg px-3 py-2 text-sm font-semibold leading-7 text-gray-900 hover:text-blue-500 hover:bg-gray-50"
                    }
                  >
                    Log in
                  </NavLink>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="mt-28 md:mt-32 mx-auto w-5/6">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-blue-300 text-lg md:text-2xl">
        Vacancy Announcement For A Dot Net Developer
        </span>
        <br /><br/>
      {/*Table Here*/}
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Sl. No</th>
            <th style={thStyle}>Designation</th>
            <th style={thStyle}>No. of Post</th>
            <th style={thStyle}>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tdStyle}>1</td>
            <td style={tdStyle}>Dot Net Developer</td>
            <td style={tdStyle}>1 slot</td>
            <td style={tdStyle}>Preferable Someone With Experience</td>
          </tr>
        </tbody>
      </table>
      
        <div className="text-sm md:text-base justify-center">
          
          Interested candidates fulfilling the qualification and experiences
          criteria may send their applications along with all the required
          documents to the <span className="text-blue-500 font-bold">   

          <a href="mailto:info@yangkhor.com?subject=Inquiry&body=Hello%20Yangkhor%20Team,">info@yangkhor.com</a>

          </span> on or before <span className="text-blue-500 font-bold">31st July 2024</span>.
          Submit the copy of the following documents: <br/><br/>
          <span className="text-black-500 font-bold">ToR Attachment: </span>
          <a className="text-blue-500 font-bold" href="assets/officedocs/Vacancy_Java Developer & .Net.pdf" download>Click here to view & download</a><br/>
          <span className="text-black-500 font-bold">Job Application Form: </span>
          <a className="text-blue-500 font-bold" href="assets/officedocs/Job Application_YPL_Final.pdf" download>Click here to view & download</a><br/><br/>
          
          <ul>
            <li>1. Curriculum Vitae/Resume</li>
            <li>2. Academic Transcript</li>
            <li>3. Training & Course Completion certificates</li>
            <li>4. Security Clearance Certificate</li>
            <li>5. Medical Cerrtificate</li>
            <li>6. NOC from the current employer, if employed.</li>
            <li>7. Filled job application form.</li>
          </ul>
          <br /><br/>
          For any queries related to the application submission, please contact our HR department,
          <span className="text-blue-500 font-bold">77719388/17390649/17368131</span> during office hours.
          <br /><br/>
          Shortlisted candidates will be informed about the interview date over
          the phone,email,facebook or at our website.
        </div>
        
        <div className="py-10">
          <hr className="border" />
          <span className="text-gray-700 text-xs">
            Please be informed that applications should be submitted only
            through online system. Applications submitted in hard copies and
            emails or applications not meeting the specified criteria and
            incomplete documents/information shall not be accepted. For further
            information, please contact HR Section at telephone number{" "}
            <b>+975-2-335378</b> and email ID- <b>info@yangkhor.com</b> during
            office hours (
            <b>9:00 AM-4:30 PM with lunch break from 1:00 PM-2 PM</b>).
          </span>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default JobListings;
