import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import Announcements from "./pages/Announcements";
import Career from "./pages/Career";
import ContactUs from "./pages/ContactUs";
import JobListings from "./pages/JobListings";
import JobDetails from "./pages/JobDetails";
import Login from "./pages/Login";
import ScrollToTop from "./components/ScrollToTop";
import CloudServices from "./pages/CloudServices";
import DataServices from "./pages/DataServices";
import NetworkingServices from "./pages/NetworkingServices";
import SecurityServices from "./pages/SecurityServices";
import SoftwareServices from "./pages/SoftwareServices";
import MicrosoftServices from "./pages/MicrosoftServices";
import TradeSolutions from "./pages/TradeSolutions";
import HRSolutions from "./pages/HRSolutions";
import BankingSolutions from "./pages/BankingSolutions";
import CitizenServices from "./pages/CitizenServices";
import RimsSolutions from "./pages/RimsSolutions";
import PmasSolutions from "./pages/PmasSolutions";
import Downloads from "./pages/Downloads";

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/joblistings" element={<JobListings />} />
        <Route path="/jobdetails" element={<JobDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cloudservices" element={<CloudServices />} />
        <Route path="/dataservices" element={<DataServices />} />
        <Route path="/networkingservices" element={<NetworkingServices />} />
        <Route path="/securityservices" element={<SecurityServices />} />
        <Route path="/softwareservices" element={<SoftwareServices />} />
        <Route path="/microsoftservices" element={<MicrosoftServices />} />
        <Route path="/tradesolutions" element={<TradeSolutions />} />
        <Route path="/hrsolutions" element={<HRSolutions />} />
        <Route path="/bankingsolutions" element={<BankingSolutions />} />
        <Route path="/citizenservices" element={<CitizenServices />} />
        <Route path="/rimssolutions" element={<RimsSolutions />} />
        <Route path="/pmassolutions" element={<PmasSolutions />} />
        <Route path="/downloads" element={<Downloads />} />
      </Routes>
    </Router>
  );
}

export default App;
