import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Announcements = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col items-center p-4 mt-5 md:mt-10 md:px-10 overflow-hidden">
        <section className="bg-white pb-10 pt-20 lg:pb-20 lg:pt-[120px]">
          <div className="container">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4">
                <div className="mx-auto mb-[60px] text-center lg:mb-20">
                  <h2 className="mb-4 text-3xl font-bold sm:text-4xl md:text-[40px] bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-blue-300">
                    Our Recent News
                  </h2>
                  <p className="text-base text-gray-500">
                    Stay up-to-date with the latest news and updates from our
                    company. We are committed to keeping you informed about
                    important developments, new product launches, and exciting
                    events. Check this space regularly for the most recent
                    announcements.
                  </p>
                </div>
              </div>
            </div>

            <div className="-mx-4 flex flex-wrap">
              <BlogCard
                date="Dec 22, 2023"
                CardTitle="Meet AutoManage, the best AI management tools"
                CardDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                image="https://i.ibb.co/Cnwd4q6/image-01.jpg"
              />
              <BlogCard
                date="Dec 22, 2023"
                CardTitle="Meet AutoManage, the best AI management tools"
                CardDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                image="https://i.ibb.co/Y23YC07/image-02.jpg"
              />
              <BlogCard
                date="Dec 22, 2023"
                CardTitle="Meet AutoManage, the best AI management tools"
                CardDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                image="https://i.ibb.co/7jdcnwn/image-03.jpg"
              />
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Announcements;

const BlogCard = ({ image, date, CardTitle, CardDescription }) => {
  return (
    <>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div className="mb-10 w-full">
          <div className="mb-8 overflow-hidden rounded">
            <img src={image} alt="" className="w-full" />
          </div>
          <div>
            {date && (
              <span className="mb-5 inline-block rounded px-4 py-1 text-center text-xs font-semibold leading-loose">
                {date}
              </span>
            )}
            <h3>
              <a
                href="/#"
                className="mb-4 inline-block text-xl font-semibold hover:text-blue-500 sm:text-2xl lg:text-xl xl:text-2xl"
              >
                {CardTitle}
              </a>
            </h3>
            <p className="text-base">{CardDescription}</p>
          </div>
        </div>
      </div>
    </>
  );
};
