import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Downloads() {
  const documents = [
    { id: 1, name: "Leave Form", url: "assets/officedocs/Leave Form_YPL.pdf" },
    { id: 2, name: "Requisition Form", url: "assets/officedocs/Requisition Form_YPL.pdf" },
    { id: 3, name: "TA DA Authorization Form", url: "assets/officedocs/TA DA Authorization Form.pdf" },
    { id: 4, name: "TA DA Claim Form", url: "assets/officedocs/TA DA Claim Form.pdf" },
    { id: 5, name: "Tour Report Form", url: "assets/officedocs/Tour Report Form_YPL.pdf" },
  ];

  return (
    <div>
      <Header />
      <div className="p-2 mb-5 mt-28 md:mt-36 bg-white text-center">
        <div className="pb-5">
          <span className="mb-10 text-3xl font-bold text-gray-700 sm:text-4xl md:text-[40px] bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-blue-300">
            Downloads
          </span>
        </div>
        <div className="flex flex-col gap-5 bg-white hover:shadow-lg p-10 mx-auto w-5/6 rounded-md border border-black lg:flex lg:flex-row items-center">
        <ul className="divide-y divide-gray-200 w-full">
          {documents.map((document) => (
            <li
              key={document.id}
              className="py-4 flex items-center justify-between w-full"
            >
              <span className="text-sm md:text-lg font-semibold">{document.id}. {document.name}</span>
              <a
                href={document.url}
                download
                className="text-blue-600 hover:text-blue-800"
              >
                Download
              </a>
            </li>
          ))}
        </ul>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Downloads;
